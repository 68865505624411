<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            {{$i18n.t('enterpriseInfo.text15')}}
        </div>
        <!-- 标题 end -->

        <!-- 内容卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%',
                overflowY: 'auto'
            }"
            :style="{
                height: tableHeight + 'px'
            }"
            shadow="always">

            <el-timeline style="margin-top: 20px;">

                <el-timeline-item
                    v-for="(item, index) in activities"
                    :timestamp="item"
                    :key="index"
                    color="#1890FF"
                    size="large"
                    :icon="'iconfont_al step_icon icon-' + (index+1)"
                    placement="top">

                    <!-- 步骤一（填写企业信息） start -->
                    <div v-if="index === 0" class="step0">
                        <el-form :model="form" :rules="rules" size="small" ref="ruleForm" class="authentication_form"
                                 :label-width="formLabelWidth">
                            <el-form-item :label="$i18n.t('enterpriseInfo.text16')" prop="enterpriseName">
                                <el-input
                                    v-model.trim="form.enterpriseName"
                                    :placeholder="$i18n.t('enterpriseInfo.text17')">
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="$i18n.t('enterpriseInfo.text18')" prop="abbreviation">
                                <el-input
                                    v-model.trim="form.abbreviation"
                                    :placeholder="$i18n.t('enterpriseInfo.text19')">
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="$i18n.t('enterpriseInfo.text20')" prop="abbreviationEN">
                                <el-input
                                    v-model.trim="form.abbreviationEN"
                                    :placeholder="$i18n.t('enterpriseInfo.text21')">
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="$i18n.t('enterpriseInfo.text22')" prop="legalPerson">
                                <el-input
                                    v-model.trim="form.legalPerson"
                                    :placeholder="$i18n.t('enterpriseInfo.text23')">
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="$i18n.t('enterpriseInfo.text24')" required :error="formErr.fileErr">

                                <div class="image" v-if="form.file">
                                    <el-button type="text" icon="el-icon-error" size="medium" @click="form.file = ''"
                                               class="close_img"></el-button>
                                    <el-image
                                        :src="form.file"
                                        :preview-src-list="[form.file]"
                                        class="avatar"></el-image>
                                </div>
                                <el-upload
                                    v-else
                                    class="avatar-uploader"
                                    :action="$message.uploadImgUrl"
                                    :data="{
                                       bucket: 'enterprise'
                                    }"
                                    :headers="{
                                        accessToken: $token.getToken().accessToken,
                                        refreshToken: $token.getToken().refreshToken,
                                        language: $message.language
                                    }"
                                    :show-file-list="false"
                                    :on-success="($event) =>{handleAvatarSuccess($event,0)}"
                                    :on-error="onErr"
                                    accept=".png,.jpeg,.jpg,.gif,.bmp"
                                    :before-upload="($event) =>beforeAvatarUpload($event,0)">
                                    <i class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <span class="tips">{{$i18n.t('enterpriseInfo.text25')}}</span>
                            </el-form-item>
                            <el-form-item :label="$i18n.t('enterpriseInfo.text26')" prop="address">
                                <el-input
                                    v-model.trim="form.address"
                                    :maxlength="100"
                                    :placeholder="$i18n.t('enterpriseInfo.text27')">
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="$i18n.t('enterpriseInfo.text28')" required :error="formErr.licenseImageErr">

                                <div class="image" v-if="form.licenseImageUrl">
                                    <el-button type="text" icon="el-icon-error" size="medium"
                                               @click="form.licenseImageUrl = ''" class="close_img"></el-button>
                                    <el-image
                                        :src="form.licenseImageUrl"
                                        :preview-src-list="[form.licenseImageUrl]"
                                        class="avatar"></el-image>
                                </div>
                                <el-upload
                                    v-else
                                    class="avatar-uploader"
                                    :action="$message.uploadImgUrl"
                                    :data="{
                                       bucket: 'enterprise'
                                    }"
                                    :headers="{
                                        accessToken: $token.getToken().accessToken,
                                        refreshToken: $token.getToken().refreshToken,
                                        language: $message.language
                                    }"
                                    :show-file-list="false"
                                    :on-success="($event) =>{handleAvatarSuccess($event,1)}"
                                    :on-error="onErr"
                                    accept=".png,.jpeg,.jpg,.gif,.bmp"
                                    :before-upload="($event) =>beforeAvatarUpload($event,1)">
                                    <i class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <span class="tips">{{$i18n.t('enterpriseInfo.text25')}}</span>
                            </el-form-item>
                            <el-form-item :label="$i18n.t('enterpriseInfo.text29')">
                                <el-input type="textarea" :rows="5" v-model="form.desc"></el-input>
                            </el-form-item>
                            <!--                            <el-form-item style="text-align: center;">-->
                            <!--                                <el-button type="primary" class="register_btn" :loading="loading" @click="register">申请注册-->
                            <!--                                </el-button>-->
                            <!--                            </el-form-item>-->
                        </el-form>
                    </div>
                    <!-- 步骤一（填写企业信息） end -->

                    <!-- 步骤二（填写联系人） start -->
                    <div v-if="index === 1" class="step1">
                        <el-form :model="contactPersonForm" :rules="contactPersonRules" size="small"
                                 ref="contactPersonRuleForm" class="contact_person_form"
                                 :label-width="formLabelWidth">
                            <el-form-item :label="$i18n.t('enterpriseInfo.text30')" prop="name">
                                <el-input
                                    v-model.trim="contactPersonForm.name"
                                    :placeholder="$i18n.t('enterpriseInfo.text31')">
                                </el-input>
                            </el-form-item>

                            <el-form-item :label="$i18n.t('enterpriseInfo.text32')" prop="tel">
                                <el-input
                                    v-model.trim="contactPersonForm.tel"
                                    :placeholder="$i18n.t('enterpriseInfo.text33')">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!-- 步骤二（填写联系人） end -->

                    <!-- 步骤三（提交申请） start -->
                    <div v-if="index === 2" class="step2">
                        <el-button type="primary" class="register_btn" size="small" :loading="loading"
                                   @click="register">
                            {{ enterpriseId ? $i18n.t('enterpriseInfo.text34') : $i18n.t('enterpriseInfo.text35') }}
                        </el-button>
                    </div>
                    <!-- 步骤三（提交申请） end -->

                </el-timeline-item>

            </el-timeline>

        </el-card>
        <!-- 内容卡片 end -->

    </div>
</template>

<script>

import adminHeader from '@/views/main/components/adminHeader';
import {mapActions} from "vuex";

export default {

    components: {
        adminHeader
    },

    data() {

        return {

            //表单label宽度
            formLabelWidth: "150px",

            //按钮加载状态
            loading: false,

            //步骤名称列表
            activities: [
                this.$i18n.t('enterpriseInfo.text36'),
                this.$i18n.t('enterpriseInfo.text37'),
                this.$i18n.t('enterpriseInfo.text38')
            ],

            //企业信息表单数据
            form: {

                //企业全称
                enterpriseName: "",

                //企业简称
                abbreviation: "",

                //英文简称
                abbreviationEN: "",

                //企业法人
                legalPerson: "",

                //企业地址
                address: "",

                //营业执照url
                licenseImageUrl: "",

                //企业简介
                desc: "",

                //法人手持身份证url
                file: "",

            },

            //联系人表单数据
            contactPersonForm: {

                //联系人
                name: "",

                //联系电话
                tel: ""

            },

            //表单自定义验证错误项
            formErr: {

                //法人手持身份证错误内容
                fileErr: "",

                //营业执照错误内容
                licenseImageErr: ""

            },

            //企业信息表单验证内容
            rules: {

                enterpriseName: [
                    {required: true, message: this.$i18n.t('enterpriseInfo.text17')},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('enterpriseInfo.text39')},
                ],

                abbreviation: [
                    {required: true, message: this.$i18n.t('enterpriseInfo.text19')},
                    {pattern: /^(?=[\S\s]{1,10}$)[\S\s]*/, message: this.$i18n.t('enterpriseInfo.text41')},
                ],

                abbreviationEN: [
                    {required: true, message: this.$i18n.t('enterpriseInfo.text21')},
                    {pattern: /^(|[a-zA-Z\s]){1,10}$/, message: this.$i18n.t('enterpriseInfo.text40')}
                ],

                legalPerson: [
                    {required: true, message: this.$i18n.t('enterpriseInfo.text23')},
                    {pattern: /^([\u4e00-\u9fa5]|[0-9_a-zA-Z]){1,10}$/, message: this.$i18n.t('enterpriseInfo.text41')}
                ],

                address: [{required: true, message: this.$i18n.t('enterpriseInfo.text27')}]

            },

            //联系人表单验证
            contactPersonRules: {

                name: [
                    {required: true, message: this.$i18n.t('enterpriseInfo.text31')},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('enterpriseInfo.text39')},
                ],

                tel: [
                    {required: true, message: this.$i18n.t('enterpriseInfo.text42')},
                    {pattern: /^0?1[0-9]{10}$/, message: this.$i18n.t('enterpriseInfo.text40')}
                ]

            },

            //卡片高度
            tableHeight: 50,

            //企业ID
            enterpriseId: null,
            language: localStorage.getItem('language')

        }

    },

    destroyed() {
        //注销resize监听
        window.removeEventListener('resize', this.setHeight);
    },

    mounted() {

        this.formLabelWidth = this.language === 'zh' ? '150px' : '240px'
        //延迟修改高度
        setTimeout(() => {
            this.tableHeight = window.innerHeight - 150;
        }, 100)

        //添加resize监听，适配卡片高度
        window.addEventListener('resize', this.setHeight);

        //判断地址栏是否携带id参数，如果携带则查询已有企业信息，表单转为修改模式
        if (this.$route.query.id) {
            this.enterpriseId = this.$route.query.id;
            this.getMsg();
        }
    },

    methods: {

        ...mapActions('enterprise', ['addEnterprise', 'enterpriseModification', 'queryEnterpriseDetailById']),

        /**
         * 获取企业详情
         */
        getMsg() {
            this.queryEnterpriseDetailById({
                enterpriseId: this.enterpriseId
            }).then(res => {
                let data = res.result;
                this.form = {
                    enterpriseName: data.fullName,
                    abbreviation: data.shortName,
                    abbreviationEN: data.shortNameEn,
                    legalPerson: data.legalPerson,
                    address: data.address,
                    licenseImageUrl: data.businessLicence,
                    desc: data.introduction,
                    file: data.legalHandIdcard
                };
                this.contactPersonForm = {
                    name: data.contacts,
                    tel: data.contactNumber
                }
            })
        },

        /**
         * 更改高度
         */
        setHeight() {
            this.tableHeight = window.innerHeight - 150;
        },

        /**
         * 注册提交表单
         */
        register() {

            //验证是否通过
            let isAdopt = true;

            //联系人表单验证
            this.$refs.contactPersonRuleForm[0].validate((valid) => {
                if (!valid) isAdopt = false;
            })

            //企业信息验证
            this.$refs.ruleForm[0].validate((valid) => {

                if (!this.form.file) {
                    isAdopt = false;
                    this.formErr.fileErr = this.$i18n.t('enterpriseInfo.text43');
                }

                if (!this.form.licenseImageUrl) {
                    isAdopt = false;
                    this.formErr.licenseImageErr = this.$i18n.t('enterpriseInfo.text44');
                    console.log("licenseImageUrl")
                }

                if (valid && isAdopt) {
                    this.loading = true;
                    this.$store.dispatch(this.enterpriseId ? 'enterprise/enterpriseModification' : 'enterprise/addEnterprise', {
                        enterpriseId: this.enterpriseId ? this.enterpriseId : null,
                        address: this.form.address,
                        businessLicence: this.form.licenseImageUrl,
                        fullName: this.form.enterpriseName,
                        introduction: this.form.desc,
                        legalHandIdcard: this.form.file,
                        legalPerson: this.form.legalPerson,
                        shortName: this.form.abbreviation,
                        shortNameEn: this.form.abbreviationEN,
                        contacts: this.contactPersonForm.name,
                        contactNumber: this.contactPersonForm.tel
                    }).then(res => {
                        this.loading = false;
                        this.$dialog.showMessage(this.enterpriseId ? this.$i18n.t('enterpriseInfo.text45') : this.$i18n.t('enterpriseInfo.text46'), this.$config.TOAST_SUCCESS);
                        this.back();
                    }, err => {
                        this.loading = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })

        },

        /**
         * 营业执照上传成功回调
         */
        handleAvatarSuccess($event, flag) {
            if ($event.success) {
                this.form[flag === 1 ? 'licenseImageUrl' : 'file'] = $event.result.fileList[0].url;
                this.formErr[flag === 1 ? 'licenseImageErr' : 'fileErr'] = "";
            } else {
                this.$dialog.showMessage($event.resultMsg, this.$config.TOAST_ERROR);
                console.log($event)
            }
        },

        /**
         * 图片上传前回调
         */
        beforeAvatarUpload(file, flag) {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isGIF = file.type === 'image/gif';
            const isBMP = file.type === 'image/bmp';
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!(isJPG || isJPEG || isPNG || isGIF || isBMP)) {
                this.$dialog.showMessage(this.$i18n.t('enterpriseInfo.text47'), this.$config.TOAST_WARNING);
            }

            if (!isLt10M) {
                this.$dialog.showMessage(this.$i18n.t('enterpriseInfo.text48'), this.$config.TOAST_WARNING);
            }

            return (isJPG || isJPEG || isPNG || isGIF || isBMP) && isLt10M;
        },

        /**
         * 图片上传失败回调
         */
        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },


        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        }

    }

}

</script>

<style scoped>
.close_img {
    color: black;
    position: absolute;
    right: 4px;
    margin-top: -14px;
    z-index: 99;
}

.image {
    position: relative;
    width: 100px;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px;
}

.tips {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
}

/deep/ .step_icon {
    margin-top: 3.5px
}

.el-card {
    border: none;
    margin: 60px 20px 20px 20px;
    border-radius: 8px;
}

.authentication_form, .contact_person_form {
    width: 70%;
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}

.main-icon {
    font-size: 14px;
    color: #000000;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/deep/ .el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

/deep/ .el-timeline-item__tail {
    left: 7px;
}

/deep/ .el-timeline-item__timestamp {
    font-size: 16px;
    color: #000;
}

.step0, .step1 {
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.step2 {
    margin-top: 20px;
    margin-bottom: 50px;
}
</style>
