<template>
    <div class="header">
        <div class="header_logo">
            <img src="../../../assets/logo_new.png" class="logo" @click="toMain">
        </div>

        <!-- 企业菜单 start -->
        <el-dropdown trigger="click" placement="bottom" class="header_company" :disabled="loadingCompany" @command="handleCommand">
            <span class="el-dropdown-link-company">
                {{ currentCompany.name }}
                <i :class="(!loading ? 'el-icon-arrow-down' : 'el-icon-loading') + ' el-icon--right'"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="item in companyList"
                    :key="item.id"
                    :command="item"
                    :class="currentCompany.id == item.id ? 'company_list_item_activate':''"
                    :disabled="currentCompany.id == item.id">
                    {{ item.name }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <!-- 企业菜单 end -->

        <!-- 功能菜单 start -->
        <div class="header_user">
            <div class="user_name">{{ userInfo.telephone ? userInfo.telephone : userInfo.email }}</div>
            <el-dropdown trigger="click" placement="bottom-start" style="cursor: pointer;" class="header_user"
                         @command="handleUserCommand">
                <span class="el-dropdown-link">
                    <el-avatar :src="userInfo.headImg" style="width: 32px;height: 32px;" v-if="userInfo.headImg"></el-avatar>
                    <el-avatar :src="require('../../../assets/icon_avatar.png')" style="background: transparent;height: 32px;width: 32px" fit="contain" v-else></el-avatar>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-user-solid" command="personalCenter"
                                      v-if="menuData.settingMenuList.length > 0">{{$i18n.t("main.text1")}}
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-switch-button" command="logout">{{$i18n.t("main.text2")}}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- 功能菜单 end -->

        <div class="header_menu">
            <el-select v-model="language" style="width: 120px;" @change="selectLanguage" size="mini" :placeholder="$i18n.t('main.text10')">
                <img src="@/assets/lang.png" style="width: 14px;height: 14px;margin-top: 7px;margin-left: 4px;" slot="prefix"/>
                <el-option
                    v-for="item in languageList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
<!--            <div @click="openDoc()">{{$i18n.t("main.text3")}}</div>-->
            <div @click="toMain">{{$i18n.t("main.text4")}}</div>
            <div @click="toNotice">
                <el-badge :is-dot="isDot">
                    <i class="iconfont icon-xiaoxi-1" style="font-size: 20px"></i>
                </el-badge>
            </div>
        </div>

    </div>
</template>

<script>

import {mapState, mapActions, mapGetters} from "vuex";

export default {

    computed: {
        ...mapState('login', ['userMsg', 'menuData']),
        ...mapState('enterprise', ['enterpriseList'])
    },

    watch: {

        //用户信息
        userMsg: {
            handler(newVal) {
                console.log(newVal)
                this.userInfo = newVal;
                window.localStorage.setItem('userId', newVal.userId)
                if (!this.userInfo.enterpriseId) {
                    this.currentCompany = this.companyList[0];
                    return;
                }
                for (let i = 0; i < this.companyList.length; i++) {
                    if (this.companyList[i].id == this.userInfo.enterpriseId) {
                        this.currentCompany = this.companyList[i]
                    }
                }
            }
        },

        //菜单信息
        menuData: {
            handler(newVal) {
                console.log(newVal)
                if (newVal.homeMenuList.length === 0 || newVal.homeMenuList[0].children[0].uri !== '/index') {
                    if (newVal.settingMenuList.length !== 0) {
                        let isJumpOne = true;
                        console.log(this.$router.getRoutes())
                        this.$router.getRoutes().forEach(item => {
                            if (item.path == this.$route.path) {
                                isJumpOne = false;
                            }
                        });
                        console.log("isJumpOne", isJumpOne)
                        if (isJumpOne) this.$router.push({path: newVal.settingMenuList[0].uri});

                    } else {
                        if (this.$token.getToken().accessToken != "") {
                            this.$router.push({path: '/404'});
                        }
                    }
                } else {
                    let isJumpOne = true;
                    console.log(this.$router.getRoutes())
                    this.$router.getRoutes().forEach(item => {
                        if (item.path == this.$route.path) {
                            isJumpOne = false;
                        }
                    });
                    if (isJumpOne) this.$router.push({path: '/index'});
                }
            },
            deep: true
        },

        //企业列表
        enterpriseList: {
            handler(newVal) {
                this.companyList = newVal;
                this.getlogininfo();
            }
        }

    },

    data() {

        return {

            //企业列表
            companyList: [],

            //用户信息
            userInfo: {},

            loading: false,

            loadingCompany: false,

            //是否显示新消息提示
            isDot: false,

            //当前选择企业
            currentCompany: {
                id: null,
                name: null
            },

            language: '',

            languageList: [
                {value: 'zh', label: '中文'},
                {value: 'en', label: 'English'},
            ],

        }

    },

    beforeMount() {
        this.language = window.localStorage.getItem('language');
    },

    mounted() {
        !this.language && this.getCountryList();
        this.companyList = this.enterpriseList
        //获取用户权限树
        this.getUserAuthorityTree();
        //获取企业列表
        this.queryEnterpriseByUserId();
        //获取是否有未读消息
        this.getHasUnreadNotice();
    },

    methods: {

        ...mapGetters('login', ['getHomeMenuRedirect', 'getSettingMenuRedirect']),
        ...mapActions('login', ['getlogininfo', 'loginout', 'getUserAuthorityTree']),
        ...mapActions('login', ['queryCountryList']),
        ...mapActions('enterprise', ['queryEnterpriseByUserId', 'changeEnterprise']),
        ...mapActions('main', ['hasUnreadNotice']),


        selectLanguage(event) {
            window.localStorage.setItem('language', event);
            this.$router.go(0);
        },

        getCountryList() {
            this.queryCountryList().then(res => {
                res.result.forEach(item => {
                    if (location.hostname === 'localhost') {
                        window.localStorage.setItem('language', 'zh');
                        if (item.countryCode === 'CN') {
                            this.systemConfig = item.dataCenter.systemConfig;
                        }
                    }
                    if (location.hostname === item.dataCenter.server) {
                        this.systemConfig = item.dataCenter.systemConfig;
                        window.localStorage.setItem('language', item.countryCode === 'CN' ? 'zh' : 'en');
                    }
                })
                this.language = window.localStorage.getItem('language');
            });
        },

        /**
         * 打开文档中心
         */
        openDoc() {
            window.open(this.$message.docUrl, '_blank')
        },

        handleCommand(command) {
            console.log(command)
            this.loading = true;
            this.loadingCompany = true;
            this.changeEnterprise({
                enterpriseId: command.id
            }).then(res => {
                this.loading = false;
                this.currentCompany = command;
                this.$dialog.showMessage(this.$i18n.t('main.text5'), this.$config.TOAST_SUCCESS);
                if (this.$route.path != '/index') {
                    this.$router.push({path: "/index"});
                }

                this.$config.changeURLArg('id', '');
                this.$router.go(0);
            }, err => {
                this.loading = false;
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        handleUserCommand(command) {
            switch (command) {
                case "personalCenter":
                    if (this.getSettingMenuRedirect() != this.$route.path && this.getSettingMenuRedirect() != "") {
                        this.$router.push({path: "/setting"});
                    }
                    break;
                case "logout":
                    this.$dialog.showConfirm({
                        content: this.$i18n.t('main.text6'),
                        title: this.$i18n.t('main.text7'),
                        btn1: this.$i18n.t('main.text8'),
                        btn2: this.$i18n.t('main.text9'),
                        icon: 2
                    }, () => {
                        this.loginout();
                    }, () => {
                    })
                    break;
                default:
                    break;
            }
        },

        /**
         * 获取是否有未读消息
         */
        getHasUnreadNotice() {
            this.hasUnreadNotice().then(res => {
                this.isDot = res.success
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 前往首页
         */
        toMain() {
            console.log(this.getHomeMenuRedirect())
            if (this.getHomeMenuRedirect() !== this.$route.path && this.getHomeMenuRedirect() !== "") {
                this.$router.push({path: this.getHomeMenuRedirect()});
            }
        },

        /**
         * 前往公告页
         */
        toNotice() {

            if (this.$route.path != '/notice' && this.$route.path != "") {
                this.$router.push({path: "/notice", query: {id: "-1"}});
            }
        }

    }
}
</script>

<style scoped>
.header {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

.logo {
    height: 64px;
    cursor: pointer;
}

.header_logo {
    width: 250px;
    height: 100%;
    float: left;
}

.header_company {
    cursor: pointer;
    float: left;
    color: #000000;
    font-size: 14px;
    margin-top: 24px;
}

.company_list_item_activate {
    background-color: #e8e9ed;
    color: #494d72;
}

.el-dropdown-menu__item {
    margin: 3px 5px;
    border-radius: 3px;
}

.header_user {
    float: right;
    margin-right: 10px;
    color: #000000;
    line-height: 64px;
}

.el-avatar {
    margin-top: 16px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    float: right;
}

.el-avatar--icon {
    font-size: 14px;
}

.user_name {
    float: right;
    font-size: 14px;
}

.header_menu {
    cursor: pointer;
    float: right;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header_menu div {
    float: left;
    margin-right: 50px;
}

.header_menu div:last-child {
    float: left;
    margin-right: 20px;
}

.header_menu div:hover {
    float: left;
    color: #1890FF;
}

.el-dropdown-link-company {
    background-color: #F7F7F7;
    padding: 8px;
    border-radius: 7px;
}

.el-select {
    margin-right: 50px!important;
}

/deep/ .el-input__inner {
    font-size: 14px!important;
}
</style>
